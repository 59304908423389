import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getListOfOrganizations } from "../../API/services";
import { organizationDTO } from "../../utils/Types/DTO";
import { Container, CssBaseline, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Chip, styled, Stack, Typography, Tooltip } from "@mui/material";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
type props = {
    bgColor: string;
};
const StyleChip = styled(Chip)(({ bgColor }: props) => ({
    backgroundColor: bgColor,
    borderRadius: 3,
}));
const OrgListing = () => {
    const navigate = useNavigate();

    const [orgList, setOrgList] = useState<organizationDTO[]>();
    useEffect(() => {
        const getOrgsList = async () => {
           
            const response = await getListOfOrganizations();
                if (response.data) {
                    setOrgList(response.data);
                }
            
        };
        getOrgsList();
    }, []);

    return (
        <Container component="main">
            <CssBaseline />
            {/* <TableComponent typeOfUser="supervisor" />
             */}

            <TitleBanner title={"Organization Listing"}  rightChild={<Stack style={{ cursor: "pointer" }} onClick={() => { navigate(`/entity/add`)}} alignItems={"center"} direction={"row"} spacing={1}>
                    <AccountBalanceIcon style={{ color: 'white' }} />
                    <Typography align="center" color={"white"} variant="h1" fontSize={18} sx={{ opacity: 1 }}>Add Organization</Typography>
                
                </Stack>}/>
            <TableContainer style={{ marginTop: '10px' }} component={Paper}>
                <Table stickyHeader>
                    <TableHead><TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Access Key</TableCell>
                        <TableCell>Registration Completed</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow></TableHead>
                    <TableBody>
                        {
                            orgList && (
                                orgList.map((org) => (
                                    <TableRow key={org.id}>
                                        <TableCell>{org.organizationName}</TableCell>
                                        <TableCell>{org.accessKey}</TableCell>
                                        <TableCell><StyleChip label={org.registrationCompleted.toString()} bgColor={org.registrationCompleted ? "#d1fcd7" : "#f5aca4"} /></TableCell>
                                        <TableCell align="right">
                                            <Button
                                                sx={{ marginRight: 2 }}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => navigate(`/assessment/management/add/${btoa(org.id)}`)}
                                            >
                                                Assessment Management
                                            </Button>
                                            <Tooltip title="Coming Soon">
                                            <Button
                                                sx={{ marginRight: 2 }}
                                                variant="outlined"
                                                size="small"
                                                // disabled={true}
                                                onClick={() => navigate(`/admin/list/org/${btoa(org.id)}`)}
                                            >
                                                View  Administrators
                                            </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </Container>
    );

 };
export default OrgListing;