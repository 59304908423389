import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAssessmentDetailsWithCategories } from "../../API/services";
import { capitalizer, formatDate, getLoggedInUser } from "../../utils/util";
import { AssessmentsWithCategoriesDTO } from "../../utils/Types/DTO";
import { Container, CssBaseline, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Chip, styled } from "@mui/material";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import { useUserContext } from "../../context/UserContext";
type props = {
    bgColor: string;
};
export const StyleChip = styled(Chip)(({ bgColor }: props) => ({
    backgroundColor: bgColor,
    borderRadius: 3,
}));
const CandidatesList=()=>{
    const user = useUserContext();
    const getLoggedInUserEmail = getLoggedInUser();
    const navigate = useNavigate();
    const [assessmentData,setAssessmentData]=useState<AssessmentsWithCategoriesDTO[]>([]);
    const [filter, setFilter] = useState<string>('');
    useEffect(()=>{
        if(user.id){
            const orgID = Object.keys(user.organizationMap)[0];
            const fetchData=async()=>{
                if (getLoggedInUserEmail) {
                const response= await getAssessmentDetailsWithCategories(orgID);
                    if(response.data) setAssessmentData(response.data);
                }
            };
    
             fetchData();
        }

    },[user.id]);
    // Get unique evaluation types for the dropdown
  const evaluationTypes = Array.from(new Set(assessmentData.map(a => a.evaluation)));
  const filteredAssessments = filter
  ? assessmentData.filter(a => a.evaluation === filter)
  : assessmentData;
    return(
        <Container component="main">
            <CssBaseline />
            <TitleBanner title={"Applicant List"} />
                <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                    <option value="">All Evaluations</option>
                    {evaluationTypes.map((type, index) => (
                    <option key={index} value={type}>
                        {type}
                    </option>
                    ))}
                </select>
            <TableContainer style={{ marginTop:'10px' }} component={Paper}>
                <Table stickyHeader>
                    <TableHead>

                        <TableRow>
                            <TableCell >Name</TableCell>
                            <TableCell >Average Score</TableCell>
                            <TableCell >Top Skill</TableCell>
                            <TableCell >Evaluation</TableCell>
                            <TableCell >Date of Assessment</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {
                            filteredAssessments &&(
                                filteredAssessments.map((assessment)=>(

                                    <TableRow key={assessment.uniqueAssessmentId}>
                                        <TableCell>{assessment.name}</TableCell>
                                        <TableCell><StyleChip label={assessment.overallScore} bgColor={"#f7fcd1"} /></TableCell>
                                        <TableCell>{(assessment.topSkill)?capitalizer(assessment.topSkill):""}</TableCell>
                                        <TableCell>{assessment.evaluation}</TableCell>
                                        <TableCell>{formatDate(assessment.dateTime)}</TableCell>
                                        <TableCell align="right">
                                            <Button
                                                onClick={() => navigate(`/assessment/${btoa(assessment.uniqueAssessmentId)}/details`)}
                                                sx={{ marginRight: 2 }}
                                                variant="outlined"
                                                size="small"
                                            >
                                                View Assessment
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )
                        }

                        </TableBody>
                        </Table>
                        </TableContainer>
                        </Container>
    );
};

export default CandidatesList;