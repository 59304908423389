import { Container, Grid, Typography } from "@mui/material";
import { title } from "process";
import { useNavigate } from "react-router-dom";
import CountCard from "../Components/CountCard";
import { useState, useEffect } from "react";
import { screeningOutline } from "../../API/services";
import { skillsOutlineDTO } from "../../utils/Types/DTO";
import { getCookieByName, getLoggedInUser } from "../../utils/util";
import { useUserContext } from "../../context/UserContext";

const CandidateScores=()=>{
    const user = useUserContext();
    const getLoggedInUserEmail = getLoggedInUser();
    const navigate = useNavigate();
    const [skillOutline, setSkillOutline] = useState<skillsOutlineDTO[]>();
    useEffect(() => {
        
        const orgID = Object.keys(user.organizationMap)[0];
        const getOrgsList = async () => {
            if (getLoggedInUserEmail) {
                const response = await screeningOutline(orgID ?? '');
                if (response.data) {
                    setSkillOutline(response.data);
                }
            }
           

        };
        getOrgsList();
    }, []);

    return(
        <Container  component="main">
            {
                skillOutline && (
                    <Grid spacing={3} container direction={"column"}>
                        <Grid alignItems="center" justifyContent="center" direction={"row"} xs={12} container item>
                            <Grid xs={4} item>
                                <CountCard count={(skillOutline[0].category === 'HIGHLY_RECOMMENDED') ? skillOutline[0].count:0} bgcolor={"#d1fcd7"} onClickHandler={() => navigate("/hr/applicants/")} />
                            </Grid>
                            <Grid paddingLeft={5} xs={8} item>
                                <Typography align="left" variant="body2" fontSize={22} sx={{ opacity: 0.8 }}>
                                    Highly Recommended (Meets or exceeds all Hiring criteria)
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid alignItems="center" justifyContent="center" direction={"row"} xs={12} container item>
                            <Grid xs={4} item>
                                <CountCard count={(skillOutline[1].category === 'RECOMMENDED') ? skillOutline[1].count : 0} onClickHandler={() => navigate("/hr/applicants/")} />
                            </Grid>
                            <Grid paddingLeft={5} xs={8} item>
                                <Typography align="left" variant="body2" fontSize={22} sx={{ opacity: 0.8 }}>
                                    Recommended (Meets Leadership criteria)
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid alignItems="center" justifyContent="center" direction={"row"} xs={12} container item>
                            <Grid xs={4} item>
                                <CountCard count={(skillOutline[2].category === 'POSSIBLE') ? skillOutline[2].count : 0} bgcolor={"#f7fcd1"} onClickHandler={() => navigate("/hr/applicants/")} />
                            </Grid>
                            <Grid paddingLeft={5} xs={8} item>
                                <Typography align="left" variant="body2" fontSize={22} sx={{ opacity: 0.8 }}>
                                    Possible (Minimum meets or exceeds Top 3)
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid alignItems="center" justifyContent="center" direction={"row"} xs={12} container item>
                            <Grid xs={4} item>
                                <CountCard count={(skillOutline[3].category === 'NOT_RECOMMENDED') ? skillOutline[3].count : 0} bgcolor={"#fcd1d1"} onClickHandler={() => navigate("/hr/applicants/")} />
                            </Grid>
                            <Grid paddingLeft={5} alignItems="center" justifyContent="center" xs={8} item>
                                <Typography align="left" variant="body2" fontSize={22} sx={{ opacity: 0.8 }}>
                                    Not Recommended ( Does not meet minimum Top 3)
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid marginTop={2}>
                            <Typography align="center" variant="h4" fontSize={12} sx={{ opacity: 0.5 }}>
                                The above categories are based solely on default settings established by the US Department of Labor and/ or your organization's custom settings. This is to say that the information provided within the ESAT™ Dashboard is based entirely on these default settings and the applicant's responses to the ESAT™.
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }
         
        </Container>
    );
};
export default CandidateScores;