import AddEntity from "../components/addEntity/AddEntity";
import AddUser from "../components/AddUser/AddUser";
import Assessment from "../components/Assesment/Assesment";
import AssessmentHistory from "../components/Assesment/AssessmentHistory";
import AddPromo from "../components/Assesment/licenseManagement/AddPromo";
import Home from "../Dashboard/Home";
import HRHome from "../HrDashboard/HRHome";
import OrgListing from "../Listing/OrgListing/OrgListing";
import SupervisorDetails from "../Listing/SupervisorListing/SupervisorDetails";
import SupervisorListing from "../Listing/SupervisorListing/SupervisorListing";
import UserListing from "../Listing/UserListing/UserListing";
import AddAssessment from "../components/Assesment/licenseManagement/AddAssessment";
import AddSupervisors from "../components/addSupervisors/AddSupervisors";
import AssessmentSelectionComponent from "../components/Assesment/consumeLicense/preScreen";
import TestTakersComponent from "../components/Assesment/consumeLicense/assessmentTakers";
import ReviewAndSubmitComponent from "../components/Assesment/consumeLicense/assessmentReview";
import UserHome from "../Dashboard/UserHome";
import CandidatesList from "../HrDashboard/CandidateScores/CandidatesList";
import PasswordChange from "../components/password/PasswordChange";
import MyProfile from "../components/MyProfile/MyProfile";
import Teachables from "../components/Teachables/Teachables";
import AddAdmin from "../components/addAdmin/AddAdmin";
import AddSkill from "../components/addSkill/AddSkill";
import PaymentPricePage from "../components/Payment/Components/PaymentPricePage";
import UserDefinedSkills from "../components/UserDefinedSkills/UserDefinedSkills";
import UpdateSupervisor from "../components/addSupervisors/updateSupervisors";
import AdminListing from "../Listing/AdminListing/AdminListing";
import AdminListingByOrg from "../Listing/AdminListing/AdminListingByOrg";

export const ProtectedRoutes = [
  {
    path: "/assessment/:assessmentId/details",
    element: <Assessment />
  },
  {
    path: "/assessment/history/:candidateEmail",
    element: <AssessmentHistory />
  },
  {
    path: "/assessment/assign",
    element: <AssessmentSelectionComponent />
  },
  {
    path: "/assessment/assign/respondents",
    element: <TestTakersComponent />
  },
  {
    path: "/assessment/assign/respondents/review",
    element: <ReviewAndSubmitComponent />
  },
  {
    path: "/users/add",
    element: <AddUser />
  },
  {
    path: "/users/",
    element: <UserListing />
  },
  {
    path: "hr/applicants/",
    element: <CandidatesList />
  },
  {
    path: "/supervisors/",
    element: <SupervisorListing />
  },
  {
    path: "/supervisors/:supervisorID/details",
    element: <SupervisorDetails />
  },
  {
    path: "/supervisors/add",
    element: <AddSupervisors />
  },
  {
    path: "/supervisors/update/:supervisorId",
    element: <UpdateSupervisor />
  },
  {
    path: "/*",
    element: <Home />
  },
  {
    path: "/home",
    element: <Home />
  },
  {
    path: "/hr/users/",
    element: <UserListing />
  },
  {
    path: "/hr/home",
    element: <HRHome />
  },
  {
    path:"/update-password",
    element:<PasswordChange/>
  },
  {
    path:"/profile",
    element:<MyProfile/>
  },
  {
    path: "/add/skill",
    element: <UserDefinedSkills/>
  },
  {
    path: "/screen-buy",
    element: <PaymentPricePage/>
  },
  {
    path: "/add/admin",
    element: <AddAdmin />
  },
  {
    path:"/userdefinedskills",
    element:<UserDefinedSkills/>
  }

];
export const SuperAdminProtectedRoutes = [
  {
    path: "/assessment/:assessmentId/details",
    element: <Assessment />
  },
  {
    path: "/assessment/history/:candidateEmail",
    element: <AssessmentHistory />
  },
  {
    path: "/hr/applicants/",
    element: <CandidatesList />
  },
  {
    path: "/assessment/management/add/:orgId",
    element: <AddAssessment/>
  },
  {
    path: "/org",
    element: <OrgListing />
  },

  {
    path: "/users/add",
    element: <AddUser />
  },
  {
    path: "/users/",
    element: <UserListing />
  },
  {
    path: "/supervisors/",
    element: <SupervisorListing />
  },
  {
    path: "/supervisors/org/:organizationId",
    element: <SupervisorListing />
  },
  {
    path: "/supervisors/:supervisorID/details",
    element: <SupervisorDetails />
  },
  {
    path: "/supervisors/update/:supervisorId",
    element: <UpdateSupervisor />
  },
  {
    path: "/*",
    element: <OrgListing />
  },
  {
    path: "/home",
    element: <OrgListing />
  },
  {
    path: "/entity/add",
    element: <AddEntity />
  },
  {
    path: "/license/add-promo",
    element: <AddPromo />
  },
  {
    path:"/update-password",
    element:<PasswordChange/>
  },
  {
    path:"/profile",
    element:<MyProfile/>
  },
  {
    path:"/admin/list",
    element:<AdminListing/>
  },
  {
    path: "/admin/list/org/:organizationId",
    element: <AdminListingByOrg />
  },

];
export const UserProtectedRoutes = [
  {
    path: "/assessment/:assessmentId/details",
    element: <Assessment />
  },
  {
    path: "/assessment",
    element: <AssessmentHistory />
  },
  {
    path: "/assessment/history/:candidateEmail",
    element: <AssessmentHistory />
  },
  
  {
    path: "/*",
    element: <UserHome />
  },
  {
    path: "/home",
    element: <UserHome />
  },
  {
    path:"/update-password",
    element:<PasswordChange/>
  },
  {
    path:"/profile",
    element:<MyProfile/>
  },
  {
    path:"/courses",
    element:<Teachables/>
  }

];

export const HRProtectedRoutes = [
  {
    path: "/assessment/:assessmentId/details",
    element: <Assessment />
  },
  {
    path: "/users/",
    element: <UserListing />
  },
  {
    path: "hr/applicants/",
    element: <CandidatesList />
  },
  {
    path: "hr/home",
    element: <HRHome />
  },
  {
    path: "/*",
    element: <HRHome />
  },
  {
    path:"update-password",
    element:<PasswordChange/>
  },
  {
    path:"/profile",
    element:<MyProfile/>
  }
];
