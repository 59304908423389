import { addPromoDTO, entityDTO, PasswordChangeDTO, registerPersonDTO, UserDetailsDTO } from "../../../../utils/Types/DTO";
import axios from "../../../axios";

const contextPath = '/user';

export const getSupervisors = (loggedInUser: string) => axios.get(`${contextPath}/getSupervisors?userId=${encodeURIComponent(loggedInUser)}`);

export const getCandidates = (loggedInUser: string) => axios.get(`${contextPath}/getCandidates?userId=${encodeURIComponent(loggedInUser)}`);

export const getLoggedInUserDetails = (loggedInUser: string) => axios.get(`${contextPath}/getUserDetails?userId=${encodeURIComponent(loggedInUser)}`);

export const getAssessmentDetailsById = (assessmentId: string) => axios.get(`${contextPath}/getCandidateScoresByAssessmentId?uniqueId=${assessmentId}`);

export const getAssessmentHistory = (loggedInUser: string) => axios.get(`${contextPath}/getAssessmentHistory?userId=${encodeURIComponent(loggedInUser)}`);

export const getUsersCount = (loggedInUser: string) => axios.get(`${contextPath}/getUsersOutline?organizationId=${encodeURIComponent(loggedInUser)}`);

export const getListOfOrganizations = () => axios.get(`${contextPath}/getListOfOrganizations`);

export const getSkillsAverage = (loggedInUser: string) => axios.get(`${contextPath}/getSkillsAverage?organizationId=${encodeURIComponent(loggedInUser)}`);

export const getSupervisorsByOrgId = (organizationId: string) => axios.get(`${contextPath}/getSupervisorsByOrgId?organizationId=${organizationId}`);

export const screeningOutline = (loggedInUser: string) => axios.get(`${contextPath}/screeningOutline?organizationId=${encodeURIComponent(loggedInUser)}`);

export const registerEntity = (entity: entityDTO) => axios.post(`${contextPath}/addEntity`, entity);

export const userRegistration = (payload: registerPersonDTO) => axios.post(`${contextPath}/registerFirstPerson`, payload)

export const validateAccessKey = (accessKey: any) => axios.post(`${contextPath}/validateAccessKey`, accessKey);

export const assignPromo = (payload: addPromoDTO) => axios.post(`${contextPath}/assignPromoLicense`, payload);

export const getLicenseAvailable = (organizationId: string) => axios.get(`${contextPath}/getAvailableLicenses?organizationId=${organizationId}`);

export const getAllAssessmentTypes = () => axios.get(`${contextPath}/getAllAssessmentTypes`);

export const buyAssessment = (organizationId: string, payload: any) => axios.post(`${contextPath}/buyProduct?organizationId=${organizationId}`, payload);

export const addSupervisor = (payload: any) => axios.post(`${contextPath}/userRegistration`, payload);

export const consumeLicenses = (payload: any) => axios.post(`${contextPath}/assignLicense`, payload);

export const updateUserDetails = (payload:UserDetailsDTO)=>axios.put(`${contextPath}/updateUserDetails`,payload);

export const getCandidateScoreByEmail = (email:string)=>axios.get(`${contextPath}/getCandidateScores?userId=${encodeURIComponent(email)}`);

export const getOrganizationdetailsById = (organizationId:string)=> axios.get(`${contextPath}/getOrganizationDetailsByOrganizationId?organizationId=${organizationId}`);

export const getAssessmentDetailsWithCategories=(email:string)=>axios.get(`${contextPath}/getAssessmentsWithCategories?organizationId=${encodeURIComponent(email)}`);

export const getCandidateCourseProgress =(email:string)=>axios.get(`${contextPath}/getCandidateCourseProgress?userId=${encodeURIComponent(email)}`);

export const downloadAssessmentReport = (id: string) =>axios.get(`${contextPath}/getDownloadLink?assessmentId=${encodeURIComponent(id)}`);

export const addUserDefinedSkills = (organizationId: string, payload: any) => axios.post(`${contextPath}/addUserDefinedSkillValue?organizationId=${organizationId}`, payload);

export const getUserDefinedSkills = (organizationId: string) => axios.get(`${contextPath}/getUserDefinedSkillValues?organizationId=${organizationId}`);

export const getAdministrators = (userId: string) => axios.get(`${contextPath}/getAdministrators?userId=${userId}`);

export const getAdministratorsByOrgID = (orgID: string) => axios.get(`${contextPath}/getAdministratorsByOrgId?organizationId=${orgID}`);

export const resetPassword = (userEmail:string) => axios.post(`/auth${contextPath}/forgotPassword?email=${userEmail}`);

export const validateResetToken = (token:string) => axios.get(`/auth${contextPath}/changePassword?token=${token}`);

export const savePassword = (payload:PasswordChangeDTO) => axios.post(`/auth${contextPath}/savePassword`, payload);